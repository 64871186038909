import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountantSectionService {
  constructor(private http: HttpClient) { }

  public apiUrl = environment.baseUrl;

  /*-----------------------------------------------------*/
  fetchMarketingEmployee(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .get<any>(`${this.apiUrl}data-admin/get-all-marketing-emp`, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }


  fetchRestaurantFiles(rid): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .get<any>(`${this.apiUrl}data-admin/get-rest-file-accounts/${rid}`, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  fetchStoreFiles(rid): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .get<any>(`${this.apiUrl}data-admin/get-store-file-accounts/${rid}`, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  updateRestDocStatus(st, id): Observable<any> {
    const datas = { doc_status: st, rest_id: id };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .put<any>(`${this.apiUrl}data-admin/update-rest-doc-status`, datas, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  updateStoreDocStatus(st, id): Observable<any> {
    const datas = { doc_status: st, rest_id: id };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .put<any>(`${this.apiUrl}data-admin/update-store-doc-status`, datas, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }


  addNewRestaurantLead(fData): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .post<any>(`${this.apiUrl}data-admin/add-new-restaurant-lead`, fData, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  assignEmpToRestLead(fData): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .post<any>(`${this.apiUrl}data-admin/assign-emp-to-restaurant-lead`, fData, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  updateRestLeadStatus(fData): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Channel: 'WEB_ADMIN',
      Authorization: localStorage.getItem('hkart_dadmin_token'),
    });
    return this.http
      .post<any>(`${this.apiUrl}data-admin/update-restaurant-lead-status`, fData, {
        headers,
      }).pipe(catchError(this.errorHandler));
  }

  // ------------------------------------------------
  private errorHandler(err: HttpErrorResponse): Observable<never> {
    let errmsg = '';
    if (err.status === 401 || err.status === 403) {
      localStorage.removeItem('hkart_dadmin_token');
      localStorage.removeItem('hkart_dadmin_data');
      window.location.href = '/';
    } else if (err.status === 400) {
      errmsg = err.error.message;
    } else {
      errmsg = `Server return ${err.status}, ${err.statusText}`;
    }
    return throwError(errmsg || 'Somthing Went Wrong...');
  }
}
